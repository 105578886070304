<template>
  <div>
    <el-card>
      <el-tag
        size="medium"
        style="
          margin-bottom: 50px;
          line-height: 30px;
          width: 90px;
          height: 30px;
          text-align: center;
          font-size: 14px;
        "
        >系统设置</el-tag
      >
      <el-form ref="form" :model="sysConfig" label-width="120px">
        <el-form-item label="举报邮箱" prop="reportMailbox">
          <el-input v-model="sysConfig.reportMailbox"></el-input>
        </el-form-item>
        <el-form-item label="公安备案" prop="keepRecord">
          <el-input v-model="sysConfig.keepRecord"></el-input>
        </el-form-item>
        <el-form-item label="icp备案" prop="ipc">
          <el-input v-model="sysConfig.ipc"></el-input>
        </el-form-item>
        <el-form-item label="版权所有" prop="copyright">
          <el-input v-model="sysConfig.copyright"></el-input>
        </el-form-item>
        <el-form-item label="商务邮箱1" prop="copyright">
          <el-input v-model="sysConfig.businessEmail"></el-input>
        </el-form-item>
        <el-form-item label="商务邮箱2" prop="copyright">
          <el-input v-model="sysConfig.businessEmailTwo"></el-input>
        </el-form-item>
        <el-form-item label="公司联系地址" prop="copyright">
          <el-input v-model="sysConfig.companyAddress"></el-input>
        </el-form-item>

        <el-form-item label="logo">
          <!-- <form
            action="/index/upload"
            enctype="multipart/form-data"
            method="post"
          >
            <input type="file" name="image" class="upload" @change="file"/> <br />
            <img :src="imgsrc" alt="" class="img"/>
            <input type="submit" value="上传" style="opacity: 0" ref="sde"/>
          </form> -->
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img
              v-if="sysConfig.logo"
              :src="baseurl + yu + sysConfig.logo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="微信公众号" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
            :data="action"
          >
            <img
              v-if="sysConfig.wxOfficial"
              :src="baseurl + yu + sysConfig.wxOfficial"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="小助手" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_2"
            :data="action"
          >
            <img
              v-if="sysConfig.assistant"
              :src="baseurl + yu + sysConfig.assistant"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="商务合作1" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_3"
            :data="action"
          >
            <img
              v-if="sysConfig.cooperation"
              :src="baseurl + yu + sysConfig.cooperation"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="商务合作2" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_5"
            :data="action"
          >
            <img
              v-if="sysConfig.cooperationTwo"
              :src="baseurl + yu + sysConfig.cooperationTwo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="媒体合作" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_4"
            :data="action"
          >
            <img
              v-if="sysConfig.cooperativePartner"
              :src="baseurl + yu + sysConfig.cooperativePartner"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-card>
    <el-button type="primary" style="margin-top: 50px" @click="editform">{{
      btn ? '提交修改' : 'loading..'
    }}</el-button>
    <el-button
      type="primary"
      style="margin-top: 50px; width: 98px"
      @click="rest"
      >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
    >
  </div>
</template>

<script>
import $ from 'jquery'
// import {WebUploader} from '../../assets/webuploader'

export default {
  data() {
    return {
      btn: true,
      action: {
        action: 'system'
      },
      sysConfig: {
        logo: '',
        keepRecord: '',
        ipc: '',
        copyright: '',
        email: '',
        wxOfficial: '',
        assistant: '',
        cooperation: '',
        businessEmail: '',
        companyAddress: '',
        cooperativePartner: '',
        cooperationTwo: '',
        businessEmailTwo: ''
      },

      imageUrl: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain
    }
  },
  methods: {
    rest() {
      // this.form={
      //   logo: '',
      //   keep_record: '',
      //   ipc: '',
      //   copyright: '',
      // }
      this.$refs.form.resetFields()
      this.imgsrc = ''
    },
    getform() {
      this.$http.get('/admin/SysConfig/getDetail').then(({ data: res }) => {
        console.log(res)
        if (res.errorCode == 200) {
          if (res.data.sys_config) {
            console.log(res)
            this.sysConfig = res.data.sys_config
            // this.sysConfig.logo = this.baseurl + this.sysConfig.logo
          } else {
            this.$message.error(res.message)
          }
        }
      })
    },
    handleAvatarSuccess_1(res) {
      this.sysConfig.wxOfficial = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_2(res) {
      this.sysConfig.assistant = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_3(res) {
      this.sysConfig.cooperation = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_4(res) {
      this.sysConfig.cooperativePartner = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_5(res) {
      this.sysConfig.cooperationTwo = res.data.url
      this.$forceUpdate()
    },
    editform() {
      if (this.btn) {
        // this.sysConfig.logo = this.sysConfig.logo.slice(this.baseurl.length)
        this.$http
          .post('/admin/SysConfig/editSysConfig', { sysConfig: this.sysConfig })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              $(this.$refs.sde).click()
              // location.reload()
              this.getform()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file)
      this.sysConfig.logo = res.data.url
    }
  },
  created() {
    this.getform()
    sessionStorage.setItem('action', 'system')
  },
  mounted: function() {}
}
</script>

<style lang="less" scoped>
.img {
  // width: 500px;
  height: 200px;
  width: auto;
  // height: 200px;
  border: 0;
  outline: 0;
}
img[src=''] {
  opacity: 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 178px;
  width: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  display: block;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
</style>
